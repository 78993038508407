@use "vars";
@use "mixins";

.not-front .brmas-datalist-page .main-inner{
  padding-top: 0;
}

.main-top.with-search-block{
  @include mixins.full-width();
  background-color: #F4F2EF;
  margin-bottom: 30px;

  .main-top-inner{
    padding: 30px 20px;
    margin-left: auto;
    margin-right: auto;
  }

  @media all and (min-width: 1024px){

  }

  @media all and (min-width: 1200px){
    .main-top-inner{
      padding-left: 0;
      padding-right: 0;
      max-width: 1084px;
    }
  }

}

.block-brmas-datalist.block-search{
  .views-exposed-widget{
    float: none;
    padding: 0;
  }
  .form-item{
    margin-bottom: 0;
    margin-top: 0;
    display: block;
  }
  .form-submit{
    margin-top: 0;
  }
  input[type="text"]{
    border: 0;
    height: 50px;
    padding-right: 35px;
    border-radius: 5px;
    background-image: none;
    @include mixins.font-style(17px,28px,.1em,'',#555);
  }

  input[type="submit"]{
    position: absolute;
    padding: 0;
    right: 0;
    top: 0;
    width: 100px;
    height: 50px;
    font-size: 20px;
    margin-right: 0;
    background-color: var(--theme-color-primary);
  }
}

.view.table-mode{

  .view-content{
    overflow-x: auto;
  }
  .views-table{
    min-width: 100%;
  }
  tr{
    background-color: transparent;
    border-bottom: 2px solid #aaa;
  }
  thead tr{
    border-color: #333;
  }

  td,th{
    padding: 8px 10px 10px;
    vertical-align: middle;
  }
  th{
    font-weight: bold;
    min-width: 80px;
  }
  td ul{
    padding-left: 0;
  }
  td li{
    list-style: none;
    margin-left: 0;
  }
}