.not-front .brmas-datalist-page .main-inner {
  padding-top: 0;
}

.main-top.with-search-block {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: #F4F2EF;
  margin-bottom: 30px;
}
.main-top.with-search-block .main-top-inner {
  padding: 30px 20px;
  margin-left: auto;
  margin-right: auto;
}
@media all and (min-width: 1200px) {
  .main-top.with-search-block .main-top-inner {
    padding-left: 0;
    padding-right: 0;
    max-width: 1084px;
  }
}

.block-brmas-datalist.block-search .views-exposed-widget {
  float: none;
  padding: 0;
}
.block-brmas-datalist.block-search .form-item {
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}
.block-brmas-datalist.block-search .form-submit {
  margin-top: 0;
}
.block-brmas-datalist.block-search input[type=text] {
  border: 0;
  height: 50px;
  padding-right: 35px;
  border-radius: 5px;
  background-image: none;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #555;
}
.block-brmas-datalist.block-search input[type=submit] {
  position: absolute;
  padding: 0;
  right: 0;
  top: 0;
  width: 100px;
  height: 50px;
  font-size: 20px;
  margin-right: 0;
  background-color: var(--theme-color-primary);
}

.view.table-mode .view-content {
  overflow-x: auto;
}
.view.table-mode .views-table {
  min-width: 100%;
}
.view.table-mode tr {
  background-color: transparent;
  border-bottom: 2px solid #aaa;
}
.view.table-mode thead tr {
  border-color: #333;
}
.view.table-mode td, .view.table-mode th {
  padding: 8px 10px 10px;
  vertical-align: middle;
}
.view.table-mode th {
  font-weight: bold;
  min-width: 80px;
}
.view.table-mode td ul {
  padding-left: 0;
}
.view.table-mode td li {
  list-style: none;
  margin-left: 0;
}